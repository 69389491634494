<!--suppress HtmlFormInputWithoutLabel -->
<h1>Log In</h1>

<!--<div class="actions">-->
<!--    <a mat-flat-button [href]="redirectUrl" color="primary" class="wide"-->
<!--        >Login with OneID-->
<!--        <mat-icon>public</mat-icon>-->
<!--    </a>-->
<!--</div>-->

<!--<div class="strike">-->
<!--    <span>or</span>-->
<!--</div>-->

<form [formGroup]="formGroup">
    <mat-form-field appearance="outline" subscriptSizing="fixed" floatLabel="always">
        <mat-label>Username or Email</mat-label>
        <input type="text" name="username" matInput autofocus formControlName="username" (keyup.enter)="login()" />
        <mat-error *ngIf="formGroup.controls['username'].errors">
            <span class="invalid">Required</span>
        </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" subscriptSizing="fixed" floatLabel="always">
        <mat-label>Password</mat-label>
        <input [type]="showPassword ? 'text' : 'password'" name="password" matInput formControlName="password" (keyup.enter)="login()" />
        <mat-icon matSuffix (click)="showPassword = !showPassword">
            {{ showPassword ? 'visibility_off' : 'visibility' }}
        </mat-icon>
        <mat-error *ngIf="formGroup.controls['password'].errors">
            <span class="invalid">Required</span>
        </mat-error>
    </mat-form-field>

    <button mat-flat-button color="primary" class="wide" (click)="login()" [disabled]="formGroup.invalid">Log In</button>
    <div class="reset-password">
        <a [routerLink]="['reset-password']">Reset Password</a>
    </div>
</form>

@if (errorCode !== undefined) {
<div class="response-message">
    <div *ngIf="errorCode === 423 || locked" class="failure-text">
        Account Inactive -- Your account request is currently being reviewed contact
        <a href="mailto:eagle-i@ornl.gov">eagle-i&#64;ornl.gov</a> for questions.
    </div>

    <div *ngIf="errorCode === 403 || disabled" class="failure-text">
        <fa-icon icon="exclamation" class="failure-text"></fa-icon>
        Your account has been disabled.
        <a (click)="goToReactivationPage()">Request account reactivation</a>.
    </div>

    <div *ngIf="errorCode === 401">
        <p class="failure-text">Your {{ emailOrUsername() }} or password is incorrect.</p>
    </div>

    <div *ngIf="errorCode === 400">
        <p class="failure-text">
            Please click on the link that has been sent to your email account to verify your email and wait for an account approval email
            before attempting to access your account. Contact <a href="mailto:eagle-i@ornl.gov">eagle-i&#64;ornl.gov</a> for questions
        </p>
    </div>
</div>
}

<div class="help-links">
    <p class="term-of-use">
        By logging in, you agree to our
        <a target="_blank" routerLink="/termsOfUse">Terms of Use</a>
    </p>

    <p class="user-issue">
        Trouble logging in? Email
        <a class="email-issue" href="mailto:eagle-i@ornl.gov">eagle-i&#64;ornl.gov</a>
    </p>

    <p class="request-account">
        New To EAGLE-I™?
        <a [routerLink]="['account-request']">Create an Account</a>
    </p>
</div>
